// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {puT6G3Au_: {hover: true}};

const cycleOrder = ["puT6G3Au_"];

const variantClassNames = {puT6G3Au_: "framer-v-oxk10w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "puT6G3Au_", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "puT6G3Au_", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-w6qOV", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-oxk10w", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"puT6G3Au_"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"puT6G3Au_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "sona-visual-multiplication-cards", fit: "fit", intrinsicHeight: 1881, intrinsicWidth: 1380, pixelHeight: 1881, pixelWidth: 1380, src: new URL("assets/35teHh0nC3SRr2nNTp0twIZSUc.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/35teHh0nC3SRr2nNTp0twIZSUc.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/35teHh0nC3SRr2nNTp0twIZSUc.jpg", import.meta.url).href} 1024w, ${new URL("assets/35teHh0nC3SRr2nNTp0twIZSUc.jpg", import.meta.url).href} 1500w`}} className={"framer-dangyp"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"NDsh8GQd_"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}} transition={transition} variants={{"puT6G3Au_-hover": {filter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))", WebkitFilter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0,0,0,0.25))"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-w6qOV [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-w6qOV * { box-sizing: border-box; }", ".framer-w6qOV .framer-lgdx38 { display: block; }", ".framer-w6qOV .framer-oxk10w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1080px; }", ".framer-w6qOV .framer-dangyp { aspect-ratio: 1.562021439509954 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 653px); overflow: hidden; position: relative; width: 1px; }", ".framer-w6qOV .framer-v-oxk10w .framer-oxk10w { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-w6qOV .framer-oxk10w { gap: 0px; } .framer-w6qOV .framer-oxk10w > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-w6qOV .framer-oxk10w > :first-child { margin-left: 0px; } .framer-w6qOV .framer-oxk10w > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 713
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"H0Ve9YsDZ":{"layout":["fixed","auto"]}}}
 */
const FramerzDVlIVq0g: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerzDVlIVq0g;

FramerzDVlIVq0g.displayName = "Choice AI image 3";

FramerzDVlIVq0g.defaultProps = {height: 713, width: 1080};

addFonts(FramerzDVlIVq0g, [])